import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        routes: [],
        menus: [],
        sessions: {},
        permissions:{},
        DICKV: {},
        user: JSON.parse(window.sessionStorage.getItem("user")),
        config:{
            title:'庄河汇通-运营管理平台',
            loginWelcome:'庄河汇通-运营管理平台',
            homeTitle:'运营管理平台',
}
    },
    mutations: {
        initUser(state, user) {
            state.user = user;
        },
        initRoutes(state, data) {
            state.routes = data;
        }, initMenus(state, data) {
            state.menus = data;
        },
        initPermissions(state,permissions){
            state.permissions=permissions
        },
        initDIC(state,data){
          state.DICKV=data
        },
        clear(state){
            state.routes=[]
            state.sessions={}
            state.permissions={}
            state.user={}
            state.DICKV= {}
            window.sessionStorage.removeItem("authorization")
            window.sessionStorage.removeItem("user")
        }
    },
})

store.watch(function (state) {
    return state.sessions
}, function (val) {
    localStorage.setItem('vue-chat-session', JSON.stringify(val));
}, {
    deep: true/*这个貌似是开启watch监测的判断,官方说明也比较模糊*/
})


export default store;
