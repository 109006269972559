<template>
  <div>
    <el-form
        :rules="rules"
        ref="loginForm"
        v-loading="loading"
        element-loading-text="正在登录..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :model="loginForm" >

      <el-form-item prop="username">
        <el-input size="normal" type="text" v-model="loginForm.username" auto-complete="off"
                  placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input size="normal" type="password" v-model="loginForm.password" auto-complete="off"
                  placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input size="normal" type="text" v-model="loginForm.verifyCode" auto-complete="off"
                  placeholder="点击图片更换验证码" @keydown.enter.native="submitLogin" style="width: 250px"></el-input>
        <img :src="vcUrl" @click="updateVerifyCode" alt="" style="cursor: pointer">
      </el-form-item>
      <!--      <el-checkbox size="normal" class="loginRemember" v-model="checked"></el-checkbox>-->
      <el-form-item>
        <el-button  size="normal" style="width: 100%;" type="primary" @click="submitLogin">账户密码登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>


<script>
// import {Message} from "element-ui";

export default {
  name: "UserLogin",

  data() {
    return {
      loading: false,
      vcUrl: '',
      loginForm: {
        username: '',
        password: '',
        verifyCode: '',
        type: '0'
      },
      checked: true,
      rules: {
        username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
        verifyCode: [{required: true, message: '请输入验证码', trigger: 'blur'}]
      }
    }
  },
  mounted() {
    this.updateVerifyCode()

  },
  methods: {
    updateVerifyCode() {
      this.loginForm.verifyCode = ''
      this.getRequestBlob('/sys/verifyCode?time=' + new Date()).then(data => {
        this.vcUrl = window.URL.createObjectURL(data)
      })
    },

    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postRequest('/login', this.loginForm).then(resp => {
            this.loading = false;
            if (resp) {
              if (resp.status == '200') {
                let path = this.$route.query.redirect;
                this.$router.replace({path: (path == '/' || path == undefined) ? '/home' : path});
                window.location.reload()
              } else this.updateVerifyCode()
            } else {

              this.updateVerifyCode()
            }
          })
        } else {
          return false;
        }
      });
    },
    ToWcLogin(){
      this.$router.push("WeChatLogin");
    },


  }
}


</script>
<style>


.loginRemember {
  text-align: left;
  margin: 0px 0px 15px 0px;
}

.el-form-item__content {
  display: flex;
  align-items: center;
}
</style>
