<template>
  <!--  <div style="width: 100% ;height: 100%; border: 1px solid white;   background-image: url('/oms/static/images/home.png'); position:absolute; left:0;  right:0; bottom:0; top:0; background-repeat:no-repeat; background-size: 100%;">-->
  <div class="body">
    <div>
      <div class="loginContainer">
        <h3 class="loginTitle">{{ this.$store.state.config.loginWelcome }}</h3>
        <div >
          <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
            <!--<el-tab-pane label="企业微信登录" name="2" tab-position="right"></el-tab-pane>-->
            <el-tab-pane label="用户密码登录" name="1" tab-position="left"></el-tab-pane>
            <div class="tab-content">
              <keep-alive>
                <component :is="curTabcompnents[activeName]"></component>
              </keep-alive>
            </div>
          </el-tabs>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import UserLogin from '../views/UserLogin.vue'              // 用户密码登录
import WeChatLogin from '../views/WeChatLogin.vue'          // 企业微信登录
export default {
  name: "Login",
  components: {UserLogin, WeChatLogin},
  data() {
    return {
      curTabcompnents: {
        '1': 'UserLogin',
        '2': 'WeChatLogin',
      },
      activeName: '1',
    }
  },
  mounted() {
    // this.updateVerifyCode()
    // this.getWechatAuth();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    updateVerifyCode() {
      this.loginForm.verifyCode = ''
      this.getRequestBlob('/sys/verifyCode?time=' + new Date()).then(data => {
        this.vcUrl = window.URL.createObjectURL(data)
      })
    },
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postRequest('/login', this.loginForm).then(resp => {
            this.loading = false;
            if (resp) {
              if (resp.status == '200') {
                let path = this.$route.query.redirect;
                this.$router.replace({path: (path == '/' || path == undefined) ? '/home' : path});
                window.location.reload()
              } else this.updateVerifyCode()
            } else {

              this.updateVerifyCode()
            }
          })
        } else {
          return false;
        }
      });
    },
    ToWcLogin() {
      this.$router.push("WeChatLogin");
    },
    // getWechatAuth() {
    //   console.log('Login.vue我是获取微信授权函数')
    //   let code = this.getWechatUrlKey('code')
    //   console.log('Login.vue我是获取数'+code)
    //   if (code != null && code != '') {
    //     console.log('Login.vue成功获取到微信的code')
    //     let state = this.getWechatUrlKey('state')
    //     console.log('code', code)
    //     console.log('state', state)
    //     console.log('url',window.location.href)
    //     // this.overlay=true
    //     // this.getRequest("/sys/user/codeLogin", {
    //     //   'code': code,
    //     //   'state': state,
    //     //   'appid': this.$store.state.appid
    //     // }).then(data => {
    //     //   this.overlay=false
    //     //   console.log(data)
    //     //   window.sessionStorage.setItem('token', data.obj.token)
    //     //   this.$store.commit('setUser', data.obj)
    //     //   if (data.obj.phone != null && data.obj.phone != '') {
    //     //     console.log("我是已经绑定过的用户")
    //     //     let path = this.$route.query.redirect;
    //     //     this.$router.replace({path: (path == '/' || path == undefined) ? '/home' : path});
    //     //     // window.location.reload()
    //     //   }
    //     // })
    //
    //   } else {
    //     console.log('Login.vue没有获取到微信code，开始获取')
    //     let local = window.location.href
    //     console.log("Login.vue本地地址是", local)
    //     let scope = "snsapi_base"
    //     let state = new Date().getTime()
    //     console.log("Login.vue", state+'---'+scope)
    //     this.test();
    //
    //   }
    // }
  }
}


</script>

<style>
.body{
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/static/images/home.png");
  background-size: 100% 100%;
  position: absolute;

}
.loginContainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 10em auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  /*box-shadow: 0 0 25px #cac6c6;*/
}

.loginTitle {
  margin: 15px auto 20px auto;
  text-align: center;
  color: #505458;
}
</style>
