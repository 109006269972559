<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export  default {
  beforeCreate() {
    document.title=this.$store.state.config.title
  }
}
</script>

