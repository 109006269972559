<template>
  <div id="wx_qrcode"></div>
</template>
<script>
import WwLogin from '../../public/static/js/wwLogin-1.2.4'
import {Base64} from "js-base64";

export default {
  name: 'WeChatLogin',
  data() {
    return {
      Wechat: {},
      retrieveActiveNames: '1',
      permissions: {},
      loading: false,
      datas: [],
      pm: {},

    }
  },
  mounted() {
    this.getWechat();
    this.getWechatAuth()
  },

  watch: {
    $route(to) {
      if (to.query.code) {
        this.getWechatAuth()
      }
    },
  },
  methods: {
    scanCode() {
      new WwLogin({
        self_redirect: false,
        "id": this.Wechat.wecid,
        "appid": this.Wechat.appid,
        "agentid": this.Wechat.agentid,
        "redirect_uri": encodeURIComponent(location.href),
        "state": Math.ceil(Math.random() * 1000),
        // "href": "http://xj.xiujian.net/oms/static/css/wechatCss.css",
        href: "data:text/css;base64," + Base64.toBase64("" +
            ".impowerBox .qrcode {width: 15em;}\n" +
            ".impowerBox .title {display: none;}\n" +
            ".impowerBox .info {width: 200px;}\n" +
            ".status_icon {display: none}\n" +
            ".impowerBox .status {text-align: center;}"),
        "lang": "zh",
      });
    },
    getWechat() {
      this.loading = true
      this.getRequest('/sys/user/getWechatdata', this.pm).then(data => {
        this.loading = false
        if (data.status == 200) {
          this.Wechat = data.obj
          this.scanCode();
        } else {
          return false
        }
      })
    },

    getWechatAuth() {
      console.log('UserLogin.vue我是获取微信授权函数')
      let code = this.getWechatUrlKey('code')
      console.log('UserLogin我是获取数' + code)
      if (code != null && code != '') {
        console.log('UserLogin成功获取到微信的code')
        let state = this.getWechatUrlKey('state')
        console.log('code', code)
        console.log('state', state)
        // console.log('url',window.location.href)
        // Message.error("userLog")
        this.overlay = true
        this.postRequest("/login", {
          'code': code,
          'state': state,
          'type': '1',
          'appid': this.$store.state.appid
        }).then(data => {
          this.loading = false;
          if (data) {
            if (data.status == '200') {
              let path = this.$route.query.redirect;
              this.$router.replace({path: (path == '/' || path == undefined) ? '/home' : path});
              window.location.reload()
            } else this.updateVerifyCode()
          } else {
            this.updateVerifyCode()
          }
        })

      } else {
        console.log('UserLogin没有获取到微信code，开始获取')
        let local = window.location.href
        console.log("UserLogin本地地址是", local)
        let scope = "UserLoginsnsapi_base"
        let state = new Date().getTime()
        console.log("UserLogin是", state + '---' + scope)

      }
    }, getWechatUrlKey(name) {//获取url 参数
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || ["", ""])[1].replace(/\+/g, '%20')) || null;
    }

  },

}

// watch:{
//   $route(to) {
//     if (to.query.code) {
//       this.getStaffInfo(to.query.code)
//     }
//
//   }
// }

</script>
<style>
#wx_qrcode {
  /*border: solid 1px red;*/
  margin: 0 auto;
  margin-left: 1.55em;
  height: 20em;
}
</style>
