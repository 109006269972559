import {getRequest} from "./api";

export const initMenu = (router, store) => {
    if (store.state.routes.length > 0) {
        return;
    }
    getRequest("/sys/info/menu").then(data => {
        if (data) {
            let fmtRoutes = formatRoutes(data.obj);
            router.addRoutes(fmtRoutes);
            store.commit('initRoutes', fmtRoutes);
        }
    })
    getRequest("/sys/dic/KV",{'parm': 'mer_status,mer_source,mer_flag,mer_type,gender,enabled,agency_type,api_flag,benefit_off,' +
            'business_flag,agency_aus_type,legal_id_type,audit_status,audit_flag,product_status,acct_no_type,acct_type,status,paytype,agreement,clear_mode,bnf_detail,' +
            'leapfrog,fee_cycle,sal_per_status,limit_pay,register_fund,stafftotal,operate_limit,inspect,occupation,business_place,thrcert_flag,' +
            'divide_status,unite_type,channel_flag,agency_level,rate_flag,dc_flag,cusrgc_status,qy_status,risk_status,limit_flag,accttype,log_type,' +
            'operation_type,sett_status,term_type,handlestate,bind_status'} ).then(data => {
        if (data) {
            store.commit('initDIC', data.obj);
        }
    })
}


export const formatRoutes = (routes) => {
    let fmRoutes = [];
    routes.forEach(router => {
        let {
            id,
            path,
            component,
            name,
            meta,
            iconCls,
            children,
            orderNum,
            hidden, buttons
        } = router;
        if (children && children instanceof Array) {
            children = formatRoutes(children);
        }
        let fmRouter = {
            id:id,
            path: path,
            name: name,
            iconCls: iconCls,
            meta: meta,
            children: children,
            orderNum: orderNum,
            hidden: hidden,
            buttons: buttons,
            component(resolve) {
                require(['../views/' + component + '.vue'], resolve);
            }
        }
        fmRoutes.push(fmRouter);
    })
    return fmRoutes;
}
