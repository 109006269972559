import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Home from './views/Home.vue'
import WeChatLogin from './views/WeChatLogin.vue'

Vue.use(Router)

//以下代码解决路由地址重复点击的报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

/*const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        hidden: true
    }, {
        path: '/home',
        name: 'Home',
        component: Home,
        hidden: true,
        children:[]
    },{
        path: '*',
        redirect: '/home'
    }
]
//创建方法
const createRouter = () => new Router({
    mode: 'hash',
    routes: routes
})

const router = createRouter()

export function resetRouter () {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}

export default router*/

export default new Router({
    scrollBehavior:()=>({y:0}),
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            hidden: true
        }, {
            path: '/home',
            name: 'Home',
            component: Home,
            hidden: true,
            children:[]
        },{
            path: '*',
            redirect: '/home'
        },
        {
            path: '/weChatLogin',
            name: 'WeChatLogin',
            component: WeChatLogin,
            hidden: true,
            children:[]
        }
    ]
})
