/**
 * @Description:
 * @Author: wyh19
 * @Date: 2021-06-07
 */
import Vue from 'vue'
import VCompare from './v-compare'

Vue.use(VCompare)

