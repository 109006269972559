<template>
<div>
<!--  <h1>首页</h1>-->
  <el-row :gutter="20">
    <el-col :span="6">
      <el-card shadow="always" style="height: 100px;border-radius: 20px" >
        <el-col :span="4" >
          <svg class="icon" aria-hidden="true" style="font-size:60px">
            <use xlink:href="#icon-drxx90"></use>
          </svg>
        </el-col>
        <el-col :span="18" :offset="2" style="margin-top: 10px">
          <el-row style="font-size:20px">{{dealMer.dnewmer}}</el-row>
          <el-row style="font-size:20px">当日新增商户</el-row>
        </el-col>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="height: 100px;border-radius: 20px" >
        <el-col :span="4" >
          <svg class="icon" aria-hidden="true" style="font-size:60px">
            <use xlink:href="#icon-shuliangtongji"></use>
          </svg>
        </el-col>
        <el-col :span="18" :offset="2" style="margin-top: 15px">
          <el-row style="font-size:20px">{{dealMer.mnermer}}</el-row>
          <el-row style="font-size:20px">当月新增商户</el-row>
        </el-col>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="height: 100px;border-radius: 20px" >
        <el-col :span="4" >
          <svg class="icon" aria-hidden="true" style="font-size:60px">
            <use xlink:href="#icon-_H_"></use>
          </svg>
        </el-col>
        <el-col :span="18" :offset="2" style="margin-top: 15px">
          <el-row style="font-size:20px">￥{{dealMer.ddeal}}</el-row>
          <el-row style="font-size:20px">当日交易量</el-row>
        </el-col>
      </el-card>
    </el-col>
    <el-col :span="6">
      <el-card shadow="always" style="height: 100px;border-radius: 20px" >
        <el-col :span="4" >
          <svg class="icon" aria-hidden="true" style="font-size:60px">
            <use xlink:href="#icon-qiandui"></use>
          </svg>
        </el-col>
        <el-col :span="18" :offset="2" style="margin-top: 15px">
          <el-row style="font-size:20px">￥{{dealMer.mdeal}}</el-row>
          <el-row style="font-size:20px">当月交易量</el-row>
        </el-col>
      </el-card>
    </el-col>
  </el-row>

  <el-row :gutter="20" style="margin-top: 10px">
    <el-col :span="24">
      <el-card shadow="always"  style="border-radius: 20px" >
        <div id="main" style="height:400px;"></div>
      </el-card>
    </el-col>
    <el-col :span="24" style="margin-top: 10px">
      <el-card shadow="always" style="border-radius: 20px" >
        <div id="mermain" style="height:400px;"></div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  name: "Welcome",
  data(){
    return{
      dealMer:{},
      option : {
        title: {
          text: '本月交易增长',
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          align:"auto",
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '',
            type: '',
            stack: '',
            data: []
          }
        ]
      },
      myChart:'',
      optionmer : {
        title: {
          text: '本月商户新增',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '商户新增',
            type: 'bar',
            barWidth: '50%',
            data: []
          }
        ]
      },
      merChart:''

    }
  },mounted() {
    this.init();
  },methods:{
   init(){
     this.myChart = echarts.init(document.getElementById('main'));
     this.getRequest("/sys/welcome/echart").then(data => {
       this.loading = false
       if (data.status == 200) {
         this.option.legend.data = data.obj.legend_data//交易类型
         this.option.xAxis.data = data.obj.xAxis_data//时间
         this.option.series = data.obj.series//数据
         this.option.title.text='本月交易趋势图'
         this.dealMer=data.obj.dealMer
         this.myChart.setOption(this.option);
       }
     }),

     this.merChart = echarts.init(document.getElementById('mermain'));
     this.getRequest("/sys/welcome/merechart").then(data => {
       this.loading = false
       if (data.status == 200) {
         this.optionmer.xAxis[0].data = data.obj.xAxis_data//时间
         this.optionmer.series[0].data = data.obj.series_data//数据
         this.merChart.setOption(this.optionmer);
       }
     })
      //监听图表容器大小

     const self = this;//因为箭头函数会改变this指向，指向windows。所以先把this保存
     setTimeout(() => {
       window.addEventListener('resize', function() {
         self.myChart.resize();
         self.merChart.resize();
       })
     },200)
   }
  }


}
</script>

<style scoped>
.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
