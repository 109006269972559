import axios from 'axios'
import {Message} from 'element-ui';
import router from '../router'
import store from "@/store";
import {Base64} from "js-base64";

axios.interceptors.request.use(config => {
    let authorization = window.sessionStorage.getItem("authorization");
    if (authorization) {
        config.headers['Authorization'] = authorization;
    }
    return config;
})
axios.interceptors.response.use(success => {
    if (success.headers.setauthorization) {
        let authorization = success.headers.setauthorization;
        let as = authorization.split('.');
        store.commit('initUser', JSON.parse(Base64.decode(as[1])));
        window.sessionStorage.setItem("user", Base64.decode(as[1]));
        window.sessionStorage.setItem("authorization", authorization)
    }
    if (success.status && success.status == 200 && success.data.status == 500) {
        Message.error({message: success.data.msg})
        return;
    }
    // if (success.data.status!==200) {
    //     Message.success({message: success.data.msg})
    // }
    return success.data;
}, error => {
    if (error.response.status == 504 || error.response.status == 404) {
        Message.error({message: '服务器被吃了( ╯□╰ )'})
    } else if (error.response.status == 403) {
        Message.error({message: '权限不足'})
    } else if (error.response.status == 401) {
        Message.error({message: error.response.data.msg ? error.response.data.msg : '登录失败，请重新登录'})
        router.replace('/');
    } else {
        Message.error({message: '未知错误!' + error.status})
    }
    return;
})

let base = '/omsapi';

export const postKeyValueRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let i in data) {
                ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
            }
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}
export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    })
}
export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params
    })
}
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params
    })
}
export const getRequestBlob = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        responseType: 'blob',
        params: params
    })
}
export const postRequestBlob = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        responseType: 'blob',
        data: params
    })
}
export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        params: params
    })
}

export const postIMG = (url,params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        params: params
    })
}
