<template>
  <div>
    <el-container>
      <el-header class="homeHeader">
        <!--        <div class="title">-->
        <!--          <div style="float:left;">-->
        <!--            <span><img style="margin-left: -5px; margin-top: 12px"  width="240px" height="60px" src="@/assets/images/logo.png"/></span>-->
        <!--          </div>-->
        <!--          <div style="float: left; margin-top: 28px;margin-left: 1em">-->
        <!--            <span >{{ this.$store.state.config.homeTitle }}</span >-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="block">
          <div class="block" style="float: left; margin: 0;">
            <el-image :src="require('../../public/static/images/logo.png')"
                      style="width: 240px ;margin-top: 0.5em;"></el-image>

          </div>
          <div class="block"
               style="float: left;  margin-top: 0.85em;  font-size: 25px;  font-family: 'Microsoft JhengHei';  color: #FFF;">
            <span>{{ this.$store.state.config.homeTitle }}</span></div>
        </div>
        <div>
          <el-button icon="el-icon-bell" type="text" style="margin-right: 8px;color: #DADADA;"
                     size="normal"></el-button>
          <el-dropdown class="userInfo" @command="commandHandler">
          <span class="el-dropdown-link">
            {{ user.name }}
            <!--    <i><img :src="user.userface" alt=""></i>-->
          </span>
            <el-dropdown-menu slot="dropdown">
              <!--              <el-dropdown-item command="userinfo">修改密码</el-dropdown-item>-->
              <el-dropdown-item command="logout" divided>注销登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container  >
        <el-aside width="200px" class="navmenu" >
          <el-menu router unique-opened   active-text-color="#ffd04b" >
            <el-submenu :index="index+''" v-for="(item,index) in routes" :key="index" >
              <template slot="title">
                <i style="color: #FFF;margin-right: 5px" :class="item.iconCls" class="menuicon"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item :index="child.path" v-for="(child,indexj) in item.children" :key="indexj"  v-show="child.hidden==0">
                <!--                <span>{{ child.name }}</span>-->
                <template slot="title" >
                  <i style="color: #FFF;" class="menuicon el-icon-plus" ></i>
                  <span>{{ child.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>

        </el-aside>
        <el-main>
          <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ this.$router.currentRoute.name }}</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="homeWelcome" v-if="this.$router.currentRoute.path=='/home'">
            <Welcome/>

          </div>
          <router-view class="homeRouterView" :key="key"/>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
        title="编辑个人信息"
        :visible.sync="userDialog"
        width="40%">
      <el-form :model="userinfo" :rules="rules" ref="editForm" label-width="180px">
        <el-row>
          <el-col :span="20">
            <el-form-item label="用户名" prop="username">
              <el-input autocomplete="off" disabled type="text" v-model="userinfo.username"
                        placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="姓名" prop="name">
              <el-input autocomplete="off" type="text" v-model="userinfo.name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="旧密码" prop="oldpassword">
              <el-input autocomplete="off" type="password" v-model="userinfo.oldpassword"
                        placeholder="请输入旧密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="密码" prop="password">
              <el-input autocomplete="off" type="password" v-model="userinfo.password"
                        placeholder="请输入密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="确认密码" prop="password">
              <el-input autocomplete="off" type="password" v-model="userinfo.againpassword"
                        placeholder="请再次输入密码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item>
              <el-button type="primary" @click="submitUser">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {Message} from "element-ui";
import Welcome from "@/views/Welcome";

export default {
  name: "Home",
  components: {Welcome},
  data() {
    return {
      test: 0,
      userDialog: false,
      userinfo: {},
      rules: {
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {
            pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/,
            message: '密码为数字,大小写字母,特殊符号,至少包含三种,长度为 8-30位'
          }
        ],
        againpassword: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ]
      }
    }
    // eslint-disable-next-line no-unreachable
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.info.password) {
        callback(new Error('两次输入密码不一致1!'))
      } else {
        callback()
      }
    };
  },
  computed: {
    key() {
      // return this.$route.path
      return this.$route.name
    },
    routes() {
      return this.$store.state.routes;
    },
    user() {
      return this.$store.state.user;
    }
  },

  mounted() {
    // this.initWebSocket()
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout() {
      this.$store.commit("removeToken");
      this.$router.push("/login");
    },
    topash(path) {
      this.$router.push(path);
    },
    //   initWebSocket() {
    //     if (typeof (WebSocket) === "undefined") {
    //       alert("您的浏览器不支持socket")
    //     } else {
    //       // 实例化socket
    //       this.socket = new WebSocket('ws://192.168.1.201:8888/api/ws')
    //       // 监听socket连接
    //       this.socket.onopen = this.open
    //       // 监听socket错误信息
    //       this.socket.onerror = this.error
    //       // 监听socket消息
    //       this.socket.onmessage = this.getMessage
    //     }
    //   },
    //   open: function () {
    //     console.log("socket连接成功")
    //   },
    //   error: function () {
    //     console.log("连接错误")
    //   },
    //   getMessage: function (msg) {
    //     console.log(msg.data)
    //   },
    //   send: function () {
    //     this.socket.send("123456")
    //   },
    //   close: function () {
    //     console.log("socket已经关闭")
    //   }
    // ,
    commandHandler(cmd) {
      if (cmd == 'logout') {
        this.$confirm('此操作将注销登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit("clear")

          this.$router.replace("/");
          this.getRequest("/logout");

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
        // } else if (cmd == 'userinfo') {
        //   this.$router.push('/userinfo');
      } else if (cmd == "userinfo") {

        this.userinfo = JSON.parse(JSON.stringify(this.$store.state.user));
        this.userDialog = true
      }
    },
    submitUser() {
      if ((this.userinfo.password == null || this.userinfo.password == '') && this.userinfo.againpassword != null && this.userinfo.againpassword != '') {
        Message.warning('请确认密码')
        return false
      }
      if (this.userinfo.password != null && this.userinfo.password != '') {
        if (this.userinfo.againpassword == null || this.userinfo.againpassword == '') {
          Message.warning("请再次输入密码")
          return false
        }
        if (this.userinfo.password != this.userinfo.againpassword) {
          Message.warning('密码不一致，请重新输入')
          return false
        }
      }
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.putRequest('/sys/info/changePwd', this.userinfo).then(data => {
            if (data.status == 200) {
              if (data.obj == 1) {
                Message.success("修改成功")
              }
              if (data.obj == 3) {
                Message.error("旧密码错误")
              }
            } else {
              Message.error({message: data.msg + '(' + data.status + ')'})
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });


    },

  }
}
</script>

<style>
.homeRouterView {
  margin-top: 10px;
}

/*.homeWelcome {*/
/*  text-align: center;*/
/*  font-size: 30px;*/
/*  font-family: 宋体;*/
/*  color: #409eff;*/
/*  padding-top: 50px;*/
/*}*/

.homeHeader {
  background-color: #1986D2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  box-sizing: border-box;
}

.homeHeader .title {
  font-size: 25px;
  font-family: 宋体;
  color: #404040;
}

.homeHeader .userInfo {
  cursor: pointer;
}

.el-dropdown-link img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-left: 8px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
  color: #DADADA;
}
.navmenu{
  /*background-color: black;*/
  /*border: solid 1px red;*/
  /*height: 53em;*/
}
.el-menu{
  /*background-color:#1986D2;*/
  /*text-color:#F4FAFF;*/
  /*active-text-color:#ffd04b;*/
}

.el-submenu{
  background-color:#1986D2;
  border-top: solid 1px black;
}
.el-submenu span{
  color: #FFF;
}
/*.submenu :hover{*/
/*  background-color: red;*/
/*}*/
.el-menu-item{
  background-color:#333333;
  border-top: solid 1px black;
}
.el-menu-item span{
  color: #FFF;
}
/*.el-submenu:active{*/
/*  background-color: red;*/
/*  color: #ffd04b;*/
/*}*/
.el-submenu__title:hover {
  background-color: #28A2F7 !important;
}
.el-menu-item:hover{
  background-color: #515151 !important;
}
.el-menu-item.is-active{
  background-color: #515151 !important;
}
.el-submenu__icon-arrow.el-icon-arrow-down{
  color: #FFF;
}
.menuicon{
  font-size: 1px  !important;
}

</style>
